import React, { useState } from 'react';

const CopyTemplateButton = () => {
  const textToCopy = `
Section 1: MBTI

Extraversion (E) vs. Introversion (I):
Where do you get your energy?
Does socializing tend to drain you, excite you, or does it vary?

Sensing (S) vs. Intuition (N):
How do you gather information?
Would you describe yourself as someone who is practical or more imaginative?

Thinking (T) vs. Feeling (F):
How do you generally make decisions?
Do you consider yourself more analytical or empathetic?

Judging (J) vs. Perceiving (P):
How do you approach your outer world?
Do you find yourself more likely to complete things ahead of schedule or rush to meet deadlines?

Section 2: Enneagram

Core Desire:
What is the fundamental thing you feel you need in life? (Examples: security, love, success, inner peace, to make a difference, etc.)

Core Fear:
What is your greatest underlying fear? (Examples: being worthless, unloved, incapable, out of control, etc.)

Primary Motivation:
What seems to consistently drive your actions and choices?

Defense Mechanism:
How do you tend to react when stressed or feel emotionally vulnerable? (Examples: withdraw, become critical, try to please others, focus on tasks, etc.)

Holy Idea: (This can be tricky to understand)
What is the ideal quality you believe would make you whole or complete? (Examples: perfect order, boundless love, absolute competence, etc.)
`;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      alert('Template copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <button
      id="copy-button"
      type="button"
      onClick={handleCopyClick}
      className="futuristic-button"
    >
      Copy Template
    </button>
  );
};

export default CopyTemplateButton;
