// dreamchatbot.js

import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';

// Import initialized Firebase services
import { db } from '../firebase';

import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

import './chatbot.css';
import MBTIPopup from './MBTIPopup';

const DreamChatbotPage = () => {
  const {
    currentUser,
    userType,
    mbtiType,
    signInWithGoogle,
    setMbtiType,
    handleGetCredits,
    userDataLoaded,
  } = useContext(AppContext);

  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMBTIPopup, setShowMBTIPopup] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  // Reference for the chat container to handle scrolling
  const chatContainerRef = useRef(null);

  const navigate = useNavigate();

  // Initialize sessionId and handle inactivity
  useEffect(() => {
    const storedLastActivity = localStorage.getItem('lastActivity');
    const storedSessionId = localStorage.getItem('sessionId');
    const now = Date.now();

    if (!storedLastActivity || now - parseInt(storedLastActivity) > 60 * 60 * 1000) {
      // More than an hour has passed, or no lastActivity
      const newSessionId = now.toString();
      localStorage.setItem('sessionId', newSessionId);
      localStorage.setItem('lastActivity', now.toString());
      setSessionId(newSessionId);
    } else {
      // Less than an hour has passed
      localStorage.setItem('lastActivity', now.toString());
      setSessionId(storedSessionId);
    }
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (userDataLoaded && sessionId) {
      if (currentUser && userType === 'premium') {
        if (mbtiType) {
          unsubscribe = initializeConversation();
        } else {
          setShowMBTIPopup(true);
        }
      } else {
        // User is not authenticated or not premium
        setConversation([]);
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser, userType, mbtiType, userDataLoaded, sessionId]);

  useEffect(() => {
    // Scroll to the latest message when the conversation updates
    if (chatContainerRef.current) {
      // Timeout to ensure message has been rendered
      setTimeout(() => {
        const chatContainer = chatContainerRef.current;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 100);
    }
  }, [conversation]);

  const initializeConversation = () => {
    if (!currentUser) {
      console.error('No current user available');
      return;
    }
    console.log('Current user UID:', currentUser.uid);

    try {
      // Reference to the user's chat_bot_dream subcollection
      const dreamChatbotCollection = collection(
        db,
        'users',
        currentUser.uid,
        'chat_bot_dream'
      );

      // Query messages ordered by timestamp and filtered by sessionId
      const q = query(
        dreamChatbotCollection,
        where('sessionId', '==', sessionId),
        orderBy('timestamp', 'asc')
      );

      // Set up a Firestore listener for the conversation
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const messages = [];
          snapshot.docChanges().forEach((change) => {
            const docData = change.doc.data();
            const messageId = change.doc.id;

            if (change.type === 'added' || change.type === 'modified') {
              // User message
              if (docData.prompt && !docData.response) {
                messages.push({
                  sender: 'user',
                  text: docData.prompt,
                  timestamp: docData.timestamp
                    ? docData.timestamp.toMillis()
                    : Date.now(),
                  docId: messageId,
                });
              }
              // Assistant response
              if (docData.response) {
                messages.push({
                  sender: 'assistant',
                  text: docData.response,
                  timestamp: docData.responseTimestamp
                    ? docData.responseTimestamp.toMillis()
                    : Date.now(),
                  docId: messageId,
                });
              }
            }
          });

          // Update conversation state
          setConversation((prevMessages) => {
            const allMessages = [...prevMessages, ...messages];

            // Remove duplicates
            const uniqueMessages = [];
            const messageKeys = new Set();
            allMessages.forEach((msg) => {
              const key = msg.docId + msg.sender;
              if (!messageKeys.has(key)) {
                messageKeys.add(key);
                uniqueMessages.push(msg);
              }
            });

            // Sort messages by timestamp
            uniqueMessages.sort((a, b) => a.timestamp - b.timestamp);

            return uniqueMessages;
          });
        },
        (error) => {
          console.error('Error fetching conversation:', error);
          if (error.code === 'permission-denied') {
            alert(
              'You do not have permission to read messages. Please check your Firestore security rules.'
            );
          }
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error('Error initializing conversation:', error);
    }
  };

  const sendMessage = async () => {
    if (!message.trim()) return;
    if (!currentUser) {
      alert('Please login to use the dream interpreter chatbot.');
      return;
    }
    console.log('Sending message as user UID:', currentUser.uid);

    setLoading(true);

    try {
      // Reference to the user's chat_bot_dream subcollection
      const dreamChatbotCollection = collection(
        db,
        'users',
        currentUser.uid,
        'chat_bot_dream'
      );

      // Add a new document with the user's message
      await addDoc(dreamChatbotCollection, {
        prompt: message.trim(),
        mbtiType: mbtiType || 'Unknown',
        timestamp: serverTimestamp(),
        sessionId: sessionId,
      });

      // Update lastActivity in localStorage
      localStorage.setItem('lastActivity', Date.now().toString());

      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      if (error.code === 'permission-denied') {
        alert(
          'You do not have permission to send messages. Please check your Firestore security rules.'
        );
      } else {
        alert('An error occurred while sending the message.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMBTISelection = (selectedMBTI) => {
    setMbtiType(selectedMBTI);
    setShowMBTIPopup(false);
  };

  const clearConversation = () => {
    // Generate a new sessionId
    const newSessionId = Date.now().toString();
    localStorage.setItem('sessionId', newSessionId);
    localStorage.setItem('lastActivity', Date.now().toString());
    setSessionId(newSessionId);
    // Clear the conversation state
    setConversation([]);
  };

  if (!userDataLoaded) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return (
      <div className="chatbot-page">
        <div className="glass-container">
          <p>Please login to use the dream interpreter chatbot.</p>
          <button onClick={signInWithGoogle} className="futuristic-button">
            Login with Google
          </button>
        </div>
      </div>
    );
  }

  if (userType !== 'premium') {
    return (
      <div className="chatbot-page">
        <div className="glass-container">
          <p>The dream interpreter chatbot feature is available for premium users only.</p>
          <button onClick={handleGetCredits} className="futuristic-button">
            Upgrade to Premium
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="chatbot-page">
      {showMBTIPopup && <MBTIPopup onSelectMBTI={handleMBTISelection} />}

      {!showMBTIPopup && (
        <div className="glass-container">
          <h1>AI Dream Interpreter</h1>

          {/* Broom button to clear the conversation */}
          <button onClick={clearConversation} className="broom-button">
            🧹
          </button>

          <div className="chat-container" ref={chatContainerRef}>
            {conversation.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  msg.sender === 'user' ? 'user' : 'assistant'
                }`}
              >
                <div className="message">
                  <div className="message-content">{msg.text}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="message-input">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Describe your dream..."
              disabled={loading}
            />
            <button
              onClick={sendMessage}
              className="futuristic-button"
              disabled={loading}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DreamChatbotPage;
